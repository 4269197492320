import Repository from './Repository';

export default {

	getClassGroupAuditLogs(itemsPerPage, pageNumber, searchString, failedEventsOnly) {

		var response = Repository.get('Teams/GetClassGroupAuditLogs', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: pageNumber,
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc',
				failedEventsOnly: failedEventsOnly
			}
		});

		return response;
	},


	getClassGroupAuditLogsCSV(searchString, failedEventsOnly) {

		var response = Repository.get('Teams/GetClassGroupAuditLogs', {
			params: {
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc',
				failedEventsOnly: failedEventsOnly,
				csv: true
			}
		});

		return response;
	},

	getUserAuditLogs(itemsPerPage, pageNumber, searchString, failedEventsOnly) {

		var response = Repository.get('Teams/GetUserAuditLogs', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: pageNumber,
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc',
				failedEventsOnly: failedEventsOnly
			}
		});

		return response;
	},

	getUserAuditLogsCSV(searchString, failedEventsOnly) {

		var response = Repository.get('Teams/GetUserAuditLogs', {
			params: {
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc',
				failedEventsOnly: failedEventsOnly,
				csv: true
			}
		});

		return response;
	}

}
