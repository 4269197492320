import IndexedDBService from "@/utils/indexedDBUtils";

const serviceCache = new Map();

export async function getDBService(userId) {
  if (!userId) {
    throw new Error("User ID is required to initialize the database.");
  }

  if (!serviceCache.has(userId)) {
    const dbService = new IndexedDBService(userId);
    await dbService.init();
    serviceCache.set(userId, dbService);
  }

  return serviceCache.get(userId);
}

export async function addDataToStore(userId, storeName, data) {
  try {
    const dbService = await getDBService(userId);
    const recordId = await dbService.addData(storeName, data);
    // console.log(`Data successfully added with ID: ${recordId}`);
    return recordId;
  } catch (error) {
    console.error(`Failed to add data to "${storeName}":`, error);
    throw error;
  }
}

export async function removeDataFromStore(userId, storeName, id) {
  try {
    const dbService = await getDBService(userId);
    const recordId = await dbService.removeData(storeName, id);
    // console.log(`Data successfully added with ID: ${recordId}`);
    return recordId;
  } catch (error) {
    console.error(`Failed to remove data from "${storeName}":`, error);
    throw error;
  }
}

export async function createStore(userId, storeName) {
  try {
    const dbService = await getDBService(userId);
    await dbService.createObjectStore(storeName);
  } catch (error) {
    console.error(`Failed to create store "${storeName}":`, error);
    throw error;
  }
}

export async function getDataFromStore(userId, storeName, key = null) {
    try {
      // Get the database service for the user
      const dbService = await getDBService(userId);
  
      return new Promise((resolve, reject) => {
        const transaction = dbService.db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);
  
        let request;
        if (key) {
          // Fetch a single record by key
          request = store.get(key);
        } else {
          // Fetch all records
          request = store.getAll();
        }
  
        request.onsuccess = () => {
          resolve(request.result); // Return the retrieved data
        };
  
        request.onerror = () => {
          reject(new Error("Failed to fetch data from store"));
        };
      });
    } catch (error) {
      console.error("Error getting data:", error);
      throw error;
    }
}

export async function dropDatabase(userId) {
    return new Promise((resolve, reject) => {
        const dbName = `olp_${userId}`; // Generate the database name based on userId
    
        const request = indexedDB.deleteDatabase(dbName); // Delete the database
    
        request.onsuccess = () => {
            // console.log(`Database ${dbName} deleted successfully`);
            resolve(); // Resolve when the database is successfully deleted
        };
    
        request.onerror = (event) => {
            console.error(`Error deleting database ${dbName}:`, event.target.error);
            reject(new Error(`Failed to delete database: ${event.target.error}`));
        };
    
        request.onblocked = () => {
            console.warn(`Database ${dbName} is blocked and cannot be deleted`);
            reject(new Error(`Database ${dbName} is blocked and cannot be deleted`));
        };
    });
}
