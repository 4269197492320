import store from '@/store'
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import dateUtils from "@/utils/dateUtils";
import { getDBService } from "@/services/databaseService";
import router from "@/router";

const CourseRepository = RepositoryFactory.get("course");

const funcs = {
    checkLogin() {
        return store.state.auth.authenticated
    },
    getCourses() {
        if (store.state.auth.qualifications[store.state.auth.selectedQualificationIndex]) {
            return store.state.auth.qualifications[store.state.auth.selectedQualificationIndex].courses;
        }
        return []
    },  
    getSupplementarySummary() {
        let hasSupplementaryCourses = false;
        let hasFormalCourses = false;
        let courses = funcs.getCourses();
        for (let course of courses) {
            if (course.courseType == 'Supplementary') {
                hasSupplementaryCourses = true;
            } else {
                hasFormalCourses = true;
            }
        }
        return { hasSupplementaryCourses , hasFormalCourses }
    },
    getCourseNameByCode(c) {
        for (var course of funcs.getCourses()) {
            if (course.subject.code == c) {
                return course.subject.name
            }
        }
        return ''
    },   
    getCourseNameByCourseId(courseId) {
        for (var course of funcs.getCourses()) {
            if (course.courseId == courseId) {
                return course.subject.name
            }
        }
        return ''
    },      
    getLearner() {
        return store.state.auth.learners[store.state.auth.selectedLearnerIndex];
    },   
    getLearners() {
        return store.state.auth.learners;
    },      
    getQualifications() {
        return store.state.auth.qualifications;
    },      
    getRole() {
        return store.state.auth.role
    },
    isGuardian() {
        return ((store.state.auth.role.roleId == 3) || (store.state.auth.role.roleId == 8));
    },
    isLearner() {
        return ((store.state.auth.role.roleId == 4) || (store.state.auth.role.roleId == 7));
    },
    isTutorOrTeacher() {
        return ((store.state.auth.role.roleId == 2) || (store.state.auth.role.roleId == 9));
    },
    getSelectedQualification() {
        return store.state.auth.qualifications[store.state.auth.selectedQualificationIndex]
    },   
    getSelectedQualificationIndex() {
        return store.state.auth.selectedQualificationIndex
    },    
    getSelectedLearnerCourses() {
        return store.state.auth.learners[store.state.auth.selectedLearnerIndex].courses[store.state.auth.selectedQualificationIndex].courses
    },        
    getUser() {
        return store.state.auth.user;
    },
    isAdmin() {
        if (store.state.admin || [1, 5, 10, 13, 14].includes(store.state.auth.role.roleId)) return true
        return false
    },
    getUserCourseLanguageId(courseId) {
        if (store.state.auth.learners.length > 0)
            return store.state.auth.learners[store.state.auth.selectedLearnerIndex].courses[store.state.auth.selectedQualificationIndex].courses.filter(c => c.courseId == courseId)[0].languageId
        return -1
    },
    getLearnerCourseLanguageId(courseId) {
        return store.state.auth.qualifications[store.state.auth.selectedQualificationIndex].courses.courses.filter(c => c.courseId == courseId)[0].languageId
    },
    setMaintenenanceUser() {
        store.state.auth.maintenanceModeUser = true
    },
    async loadQualifications() {
        store.commit("clearQualifications");  
    
        var role = store.state.auth.role;

        if ((role.roleId == 3) || (role.roleId == 8)) { // guardian only
            await store.commit("loadLearners", []);

            // load learners (and courses) for guardian
            
            let { data } = await CourseRepository.getGuardianCourses(
                role.organisationUserRoleId
            );

            if (data.success) {
                // update store with guardian's learners
                await store.commit("loadLearners", data.data);
                await store.commit("setLearnerIndex", 0); 

            } else {
                this.$refs.errorDialog.show(data.error.message);
            }

        // not list: admin, home tutor, GS admin, CC admin, AA admin, OS teacher, home invigilator, IA admin, Academic sdmin
        // only Home Learner, Online School Learner
        } else if ((role.roleId != 1) && (role.roleId != 2) && (role.roleId != 5) && (role.roleId != 6) && (role.roleId != 10) && (role.roleId != 9) && (role.roleId != 12) && (role.roleId != 13) && (role.roleId != 14)) { 

            // load courses for home learners and Online School Learner
            let { data } = await CourseRepository.getUserCoursesGrouped(
                store.state.auth.learners[store.state.auth.selectedLearnerIndex].organisationUserRoleId
            );

            if (data.success) {

                // determine default qualification (e.g. correct period for year)
                let qualificationIndex = data.data.length - 1;
                let foundQualification = false;
                let defaultYear = dateUtils.currentYear();

                let i = 0;
                while ((i < data.data.length) && !foundQualification) {
                    if (parseInt(data.data[i].periodName) == defaultYear) {
                        qualificationIndex = i;
                        foundQualification = true;
                    }
                    i++;
                }

                // update store with user's enrolled qualifications
                await store.commit("loadQualifications", data.data);
                await store.commit("setQualification", qualificationIndex); 

            } 

        }

    },

    logout() {

    },
    getTokenValue(key) {
        var token = store.state.auth.token
        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error('Invalid token format');
        }
        const payload = atob(parts[1]);
        const payloadObj = JSON.parse(payload);
        return payloadObj[key];
    },

    getAdminStudentDetails() {
        var studentDetails = {}
        if (store.state.admin != null) {
            studentDetails.qualificationId  = store.state.admin.qualificationId 
            studentDetails.roleId = store.state.admin.roleId
            studentDetails.name = store.state.admin.name
            studentDetails.surname = store.state.admin.surname
            studentDetails.studentNr = store.state.admin.studentNr
            studentDetails.qualification = store.state.admin.qualification
            studentDetails.studentOrganisationUserRoleId = store.state.admin.studentOrganisationUserRoleId
            studentDetails.organisationUserRoleId = store.state.admin.organisationUserRoleId
            studentDetails.periodId = store.state.admin.periodId
            studentDetails.period = store.state.admin.period
            studentDetails.studentOrganisationId = store.state.admin.studentOrganisationId
        }
        return studentDetails
    },

    isTempUser() {
        return store.state.auth.tempUser
    },

    getSelectedLearnerIndex() {
        return store.state.auth.selectedLearnerIndex
    },

    getlearnerSelected() {
        return store.state.auth.learnerSelected
    },

    getMenuPersmission() {
        return store.state.auth.menuPermissions
    },

    getToken() {
        return store.state.auth.token
    },

    resetLearnerSelected() {
        store.commit("resetLearnerSelected")
    },

    async processLogin(data) {
        // extract user object from token
        const user = JSON.parse(this.decodeBase64(data.token.split(".")[1]));
        store.commit("login", {
            user: user,
            token: data.token,
            roles: data.roles,
            tempUser: data.tempUser,
            isSwitchedUser: data.isSwitchedUser ?? false,
            fileSizeUploadLimit: data.fileSizeUploadLimit,
            menuPermissions: data.menuPermissions
        });
        
        try {
            const dbService = await getDBService(this.getUserId());
        } catch (error) {
            console.error(error)
        }

        // determine index of role that server chose to sign user in with

        var roleIndex = 0;
        // while (i < data.roles.length && !foundRole) {
        for (var [index, userRole] of data.roles.entries()) {
            if (userRole.organisationUserRoleId == data.activeOrganisationUserRoleId) {
                roleIndex = index;
                break;
            }
        }
        
        // set signed in user role

        store.commit("setRole", data.roles[roleIndex]);

        // load home learner

        var role = store.state.auth.role;

        if ((role.roleId == 4) || (role.roleId == 7)) { // home learner || online school learner
            store.commit("loadLearners", [store.state.auth.user]);
        }

        if (user.role == 2 || user.role == 9) {
            var studentData = await CourseRepository.getTutorStudentCourses(
                this.getOrganisationUserRoleId(), 
            );
            await store.commit("setTutorLearners", studentData.data.data)
        }

        // load and build qualifications dropdown
        await this.loadQualifications();
        store.commit("setAuthenticated", true)
    },

    isAdminRole() {
        const role = this.getRole();
        switch (role.roleId) {
            case 1: // system administrator
            case 5: // online classes administrator
            case 6: // Client Support
            case 10: // assessment administrator
            case 13: // internal administrator
            case 14: // academic administrator
                return true 
        }
        return false
    },

    goHome(adminOnly = false) {
        const role = this.getRole();
        if (adminOnly == true && this.isAdminRole() == false) {
            return "404"
        }
        switch (role.roleId) {
            case 3: // Home Guardian
            case 4: // Home Learner
            case 7: // Online School Learner
            case 8: // Online School Guardian
                return null
                // router.push("/dashboard").catch(() => {});
            case 1: // system administrator
            case 5: // online classes administrator
            case 6: // Client Support
            case 10: // assessment administrator
            case 13: // internal administrator
            case 14: // academic administrator
                return "/admin/dashboard"
                // router.push("/admin/dashboard").catch(() => {});      
            case 2: // tutor
            case 9: // OS Teacher
                if (this.getlearnerSelected()) {
                    return null
                }
                return "/learnerConsole"
                // router.push("/learnerConsole").catch(() => {});  
            case 12: // invigilator
                return "/assessments/downloadAndUploads"
                // router.push("/assessments/downloadAndUploads").catch(() => {}); 
                break
            default:
                console.error("No way home. Add role.")    
        } 
    },

    goHomeRedirect() {
        const path = this.goHome()
        if (path) {
            router.push(path).catch(() => {});
        } else {
            router.push('/')
        }
    },

    getTutorStudentCoursesCached() {
        return store.state.auth.tutor
    },

    getCourseNameById(courseId) {
        for (var course of funcs.getCourses()) {
            if (course.courseId == courseId) {
                return course.qualification.qualificationType.toUpperCase() + ' ' + course.qualification.name.toUpperCase();
            }
        }
        return ""
    },
    getOrganisationId() {
        return store.state.auth.user.organisationId
    },
    getOrganisationUserRoleId() {
        return store.state.auth.user.organisationUserRoleId
    },
    getUserId() {
        return store.state.auth.user.userId
    },
    getOrganisationLearnerRoleId() {
        var orgId = store.state.auth.learners[store.state.auth.selectedLearnerIndex]

        if (orgId) return orgId.organisationUserRoleId
        return false
    },
    getRoleName(roleId) {
        return [
            "System Administrator",
            "Home Tutor", 
            "Home Guardian", 
            "Home Learner",
            "GS Administrator",
            "Client Support",
            "Online School Learner",
            "Online School Guardian",
            "Online School Teacher",
            "Assessment Administrator"
        ][roleId - 1]
    },
    getCookieAgreement() {
        return store.state.auth.cookieAgreement
    },
    decodeBase64(base64String) {
        const binaryString = atob(base64String);
        const binaryArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            binaryArray[i] = binaryString.charCodeAt(i);
        }
        const utf8String = new TextDecoder('utf-8').decode(binaryArray);

        return utf8String
    },
    normalizeAccent(text) {
        if (text == null) return ""
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
}
export default funcs