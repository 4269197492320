export default class IndexedDBService {
    constructor(userId) {
      this.dbName = `olp_${userId}`;
      this.db = null;
    }
  
    async init() {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.dbName);
  
        request.onsuccess = (event) => {
          this.db = event.target.result;
          // console.log(`Database ${this.dbName} opened successfully.`);
          resolve();
        };
  
        request.onerror = (event) => {
          console.error(`Error opening database: ${event.target.error}`);
          reject(event.target.error);
        };
  
        request.onupgradeneeded = (event) => {
          this.db = event.target.result;
          // console.log(`Database ${this.dbName} created/updated.`);
          resolve();
        };
      });
    }
  
    async createObjectStore(storeName) {
      return new Promise((resolve, reject) => {
        if (!this.db) {
          return reject(new Error("Database is not initialized."));
        }
  
        const version = this.db.version + 1;
        this.db.close();
  
        const request = indexedDB.open(this.dbName, version);
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
            // console.log(`Object store "${storeName}" created.`);
          }
          resolve();
        };
  
        request.onsuccess = (event) => {
          this.db = event.target.result;
          resolve();
        };
  
        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
    }

    async removeData(storeName, id) {
      return new Promise((resolve, reject) => {
        if (!this.db) {
          return reject(new Error("Database is not initialized."));
        }

        const transaction = this.db.transaction([storeName], "readwrite");
        const store = transaction.objectStore(storeName);

        const deleteRequest = store.delete(id);

        deleteRequest.onsuccess = () => {
          // Successfully deleted
          resolve(`Record with key "${id}" deleted successfully.`);
        };

        deleteRequest.onerror = (event) => {
          // Handle errors
          console.error("Error deleting data:", event.target.error);
          reject(event.target.error);
        };
      })
    }
  
    async addData(storeName, data) {
      return new Promise((resolve, reject) => {
        if (!this.db) {
          return reject(new Error("Database is not initialized."));
        }
  
        const transaction = this.db.transaction([storeName], "readwrite");
        const store = transaction.objectStore(storeName);
        
        // first check if entry exist
        const getRequest = store.get(data.id);

        getRequest.onsuccess = () => {
          if (getRequest.result) {
            // If entry exists, update it using put
            const updateRequest = store.put(data);
            
            updateRequest.onsuccess = () => {
              // console.log("successfully updated id:", data.id)
              resolve(updateRequest.result); // Return the ID of the updated record
            };
    
            updateRequest.onerror = (event) => {
              console.error("Error updating data:", event.target.error);
              reject(event.target.error);
            };
          } else {
            // If entry doesn't exist, add the new record
            const addRequest = store.add(data);
            
            addRequest.onsuccess = () => {
              // console.log("added new entry:", data.it)
              resolve(addRequest.result); // Return the ID of the added record
            };
    
            addRequest.onerror = (event) => {
              console.error("Error adding data:", event.target.error);
              reject(event.target.error);
            };
          }
        };

        getRequest.onerror = (event) => {
          console.error("Error checking if data exists:", event.target.error);
          reject(event.target.error);
        };

        // const request = store.add(data);
  
        // request.onsuccess = () => {
        //   // console.log(`Data added to "${storeName}" store.`);
        //   resolve(request.result); // Return the ID of the added record
        // };
  
        // request.onerror = (event) => {
        //   console.error("Error adding data:", event.target.error);
        //   reject(event.target.error);
        // };
      });
    }
  }
  